import React, { useEffect, useState } from "react";
import OrderTypeSelection from "../../components/order/OrderTypeSelection";
import OrderFilterTypeSelection from "../../components/order/OrderFilterTypeSelection";
import OrderStatusFilterSelection from "../../components/order/OrderStatusFilterSelection";
import OrderSortHeader from "../../components/order/OrderSortHeader";
import OrderList from "../../components/order/OrderList";
import OrderPagination from "../../components/order/OrderPagination";
import { useDispatch, useSelector } from "react-redux";
import { setFlightOrders, setRefundOrders, setReissueOrders, setTicketsPage, setVoidOrders } from "../../redux/reducers/orderSlice";
import { CircularProgress } from "@mui/material";
import getBookings from "../../controllers/booking/getBookings";
import useOrders from "../../hooks/useOrders";
import getVoidTicketRequests from "../../controllers/booking/postTicketing/void/getVoidTicketRequests";
import getRefundTicketRequests from "../../controllers/booking/postTicketing/refund/getRefundTicketRequests";
import getTicketRequests from "../../controllers/booking/getTicketRequests";
import getChangeRequests from "../../controllers/booking/postTicketing/change/getChangeRequests";


function NewOrders() {
  const {ticketFilterType} = useSelector(state => state.order);
  const { getOrders } = useOrders();
  const [ordersList,ogData] = getOrders() || [];
  const [loading,setLoading] = useState(true);
  
  const perPage = 3;
  
  useEffect(() => {
    loadData();
    //eslint-disable-next-line
  }, [ticketFilterType]);

  const dispatch = useDispatch();

  const loadData = async () => {
    setLoading(true);
    let promises = [loadCase(), 
      // loadTour(), loadHotel()
    ];
    let responses = await Promise.all(promises);
    setLoading(false);
    responses = responses.flat();
    responses = responses.filter((response) => response);
    if (responses) {
      responses.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
      // setData(responses);
    }
  };

  async function loadCase() {
    switch(ticketFilterType) {
      case 'Void Management':
        return loadVoidOrders();
      case 'Refund Management':
        return loadRefundOrders();
      case 'Re-issue Management':
        return loadChangeRequests();
      case 'Tickets':
        return loadTicketRequests();
      default: return load();
    }
  }

  async function loadChangeRequests() {
    const res = await getChangeRequests();
    let data = [];
    if (res.return) {
      res.data?.data?.map((obj) => {
        // obj?.flightBooking?.map((flightObj) =>
        data.push({ ...obj, flightBooking: [obj?.flightBooking], flightObj: obj?.flightBooking, type: "Flight" })
        // );
        return true;
      });
      dispatch(setReissueOrders(data))
      // data = data?.map((obj) => templateFlightOrderData(obj));
    }
    return data;
  }

  async function loadTicketRequests() {
    const res = await getTicketRequests();
    let data = [];
    if (res.return) {
      res.data?.data?.map((obj) => {
        // obj?.flightBooking?.map((flightObj) =>
        data.push({ ...obj, flightBooking: [obj?.flightBooking], flightObj: obj?.flightBooking, type: "Flight" })
        // );
        return true;
      });
      dispatch(setFlightOrders(data))
      // data = data?.map((obj) => templateFlightOrderData(obj));
    }
    return data;
  }

  async function loadRefundOrders() {
    const res = await getRefundTicketRequests();
    let data = [];
    if (res.return) {
      res.data?.data?.map((obj) => {
        // obj?.flightBooking?.map((flightObj) =>
        data.push({ ...obj, flightBooking: [obj?.flightBooking], flightObj: obj?.flightBooking, type: "Flight" })
        // );
        return true;
      });
      dispatch(setRefundOrders(data))
      // data = data?.map((obj) => templateFlightOrderData(obj));
    }
    return data;
  }

  async function loadVoidOrders() {
    const res = await getVoidTicketRequests();
    let data = [];
    if (res.return) {
      res.data?.data?.map((obj) => {
        // obj?.flightBooking?.map((flightObj) =>
        data.push({ ...obj, flightBooking: [obj?.flightBooking], flightObj: obj?.flightBooking, type: "Flight" })
        // );
        return true;
      });
      dispatch(setVoidOrders(data))
      // data = data?.map((obj) => templateFlightOrderData(obj));
    }
    return data;
  }
  
  async function load() {
    const res = await getBookings();
    let data = [];
    if (res.return) {
      res.data?.data?.map((obj) => {
        obj?.flightBooking?.map((flightObj) =>
          data.push({ ...obj, flightObj, type: "Flight" })
        );
        return true;
      });
      dispatch(setFlightOrders(data))
      // data = data?.map((obj) => templateFlightOrderData(obj));
    }
    return data;
  }

  // const loadTour = async () => {
  //   let response = await getTourBookings();
  //   response = response?.map((obj) => templateTourOrderData(obj));
  //   return response;
  // };

  // const loadHotel = async () => {
  //   let response = await getHotelBookings();
  //   response = response?.map((obj) => templateHotelOrderData(obj));
  //   return response;
  // };
  
  return (
    <div className={Classname.container}>
      <div className={Classname.content}>
        <OrderTypeSelection />
        <OrderFilterTypeSelection />
        <OrderStatusFilterSelection />
        <OrderSortHeader />
        {loading?
          <div className="p-4 w-full flex items-center justify-center">
            <CircularProgress />
          </div>
        :
        <div className="z-10">
          <OrderList reload={loadData} 
            // ogData={ogData} orders={ordersList}
            perPage={perPage} />
        </div>
        }
        <div>
          <OrderPagination perPage={perPage} 
            // count={parseInt(Math.max(ordersList?.length,perPage)/perPage)} 
          />
        </div>
      </div>
    </div>
  );
}

export default NewOrders;

const Classname = {
  container:
    "flex w-full min-h-screen relative bg-white flex-col items-center px-3 sm:px-10 font-main",
  content: "flex w-full max-w-7xl flex-col flex-1 pb-96",
};
