import moment from 'moment'
import React, { useEffect, useState } from 'react'
import Button1 from '../../form/Button1'
import TextInput from '../../form/TextInput'
import { CircularProgress, MenuItem } from '@mui/material'
import getAdminTeamMembers from '../../../controllers/settings/adminTeam/getTeamMembers'
import { ComparePAX } from './ComparePAX'
import { CompareFlightChange } from './CompareFlightChange'
import reissue from '../../../controllers/booking/postTicketing/change/reissue'
import { enqueueSnackbar } from 'notistack'
import createTask from '../../../controllers/booking/assignTask/createTask'
import { taskData } from '../../../data/task/taskData'

export default function AssignChange({data,callback}) {
  const [handleOption,setHandleOption] = useState('direct')
  const [details,setDetails] = useState('')
  const [staff,setStaff] = useState('')
  const [staffs,setStaffs] = useState([])
  const [loadings,setLoadings] = useState({fetchStaff: false,submit: false})

  useEffect(() => {
    loadStaff()
  },[data])

  async function loadStaff() {
    setLoadings({...(loadings||{}),fetchStaff: true})
    const res = await getAdminTeamMembers();
    setLoadings({...(loadings||{}),fetchStaff: false})
    if(res.return) {
      setStaffs(res?.data?.data||[])
    }
  }
  async function handleReissue() {
    setLoadings({...loadings,submit: true})
    const res = await reissue({changeOrderId: data?._id});
    setLoadings({...loadings,submit: false})
    if(res.return) {
        enqueueSnackbar('Ticket has been requested for change.',{variant: 'success'})
        callback && callback()
    } else enqueueSnackbar(res.msg,{variant: 'error'})
  }

  async function handleAssign() {
    if(!staff)
      return enqueueSnackbar('Please select a staff to assign to!',{variant: 'error'});

    setLoadings({...loadings,submit: true})
    const res = await createTask({...taskData,
      taskType: 'ChangeOrder',
      assignee: staff,
      note: details,
    });
    setLoadings({...loadings,submit: false})
    if(res.return) {
        enqueueSnackbar('Ticket has been requested for change.',{variant: 'success'})
        callback && callback()
    } else enqueueSnackbar(res.msg,{variant: 'error'})
  }

  async function handleSubmit() {
    if(handleOption === 'direct')
      handleReissue();
    else if(handleOption === 'assign')
      handleAssign();
  }


  
  console.log(data)
    let title = 'PAX Change'
    if(data?.newSegment?.length)
        title = 'Date Change'
  return (
    <div className='flex flex-col gap-4'>
      <div>
        <div className='flex gap-1'>
          <p>Ticket Number: </p><span>{data?.orderId}</span>
        </div>
        <div className='flex gap-1'>
          <p>Re-issue Request Time: </p><span>{data?.updatedAt ? moment(data?.updatedAt)?.format('YYYY-MM-DD, HH:mm') : ''}</span>
        </div>
      </div>
      <div className='flex justify-end'>
        <div>
          <Button1 variant='text'>Check fare rules</Button1>
        </div>
      </div>
      {title === 'PAX Change' ? 
        <ComparePAX data={{
          previous: data?.flightBooking?.at(0)?.travelers?.find(obj => obj?.id === data?.passengerInfo?.at(0)?.id),
          newData: data?.passengerInfo?.at(0)
        }} />
      :
        <CompareFlightChange data={{flight: data,oldOrder: data,query: data}} />
      }

      <div>
        <TextInput label='' select value={handleOption} onChange={(ev) => setHandleOption(ev.target.value)}>
          <MenuItem value={'direct'}>Handle By Supplier</MenuItem>
          <MenuItem value='assign' >Assign To a Team</MenuItem>
        </TextInput>
      </div>

      <TextInput label='Request Details' multiline rows={4} value={details} onChange={(ev) => setDetails(ev.target.value)} />
      
      {handleOption === 'assign' ? 
        <TextInput label='Select Staff' select
          value={staff}
          onChange={(ev) => setStaff(ev.target.value)}
          InputProps={{
            startAdornment: loadings?.fetchStaff ? <CircularProgress className='!w-4 !h-4' /> : null
          }}
        >
          {staffs?.map((obj,i) => (
            <MenuItem key={i} value={obj?._id || obj?.id}>{obj?.firstName}</MenuItem>
          ))}
        </TextInput>
      :null}

      <div>
        <Button1 loading={loadings?.submit} onClick={handleSubmit}>{handleOption === 'assign' ? 'Assign Request' : 'Send To Supplier'}</Button1>
      </div>
    </div>
  )
}
